@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/utilities/motta_mixins.scss";
@import "../sass/utilities/animation";

//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/login";

// STYLE SALEBRAND
// @import "../sass/sections/salebrand/salebrand_style";

// STYLE MOTTA
@import "../sass/sections/motta/motta_menu.scss";
@import "../sass/sections/motta/motta_intro_home.scss";
@import "../sass/sections/motta/motta_quem_somos.scss";
@import "../sass/sections/motta/motta_nosso_time.scss";
@import "../sass/sections/motta/motta_areas_atuacao.scss";
@import "../sass/sections/motta/motta_artigos.scss";
@import "../sass/sections/motta/motta_contato.scss";
@import "../sass/sections/motta/motta_footer.scss";
@import "../sass/sections/motta/motta_trabalhe_conosco.scss";
@import "../sass/sections/motta/motta_trabalhe_conosco_2.scss";
@import "../sass/sections/motta/motta_esg.scss";


@import "../sass/sections/motta/sobre/motta_sobre_nos_banner.scss";
@import "../sass/sections/motta/sobre/motta_sobre_nos.scss";
@import "../sass/sections/motta/sobre/motta_sobre_timeline.scss";
@import "../sass/sections/motta/sobre/motta_premios_reconhecimento.scss";
@import "../sass/sections/motta/sobre/motra_associacoes.scss";


@import "../sass/sections/motta/nossa_equipe/nossa_equipe.scss";
@import "../sass/sections/motta/nossa_equipe/seletor-list-equipe.scss";
@import "../sass/sections/motta/nossa_equipe/nossa_equipe_detalhes.scss";


@import "../sass/sections/motta/areas_atuacao/motta_areas.scss";

@import "../sass/sections/motta/blog/motta_blog_banner.scss";
@import "../sass/sections/motta/blog/motta_blog.scss";
@import "../sass/sections/motta/blog/motta_artigos_blog.scss";
@import "../sass/sections/motta/blog/motta_ranking.scss";
@import "../sass/sections/motta/blog/newsletter.scss";


//FONTS



// @font-face {
//   font-family: "Metropolis";
//   src:  url(../fonts/Raleway-Bold.ttf);
//   font-weight: 600;
// }

// @font-face {
//  font-family: "Metropolis";
//  src: url(../fonts/Raleway-ExtraBold.ttf);
//  font-weight: 800;
// }
// PÁGINAS
main {
    overflow: hidden;
}

.pagination {

    .page-list {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;

        a {
            padding: .1875rem .7813rem;
            display: block;
        }
    }

    li {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 3px;
        /* identical to box height, or 150% */


        letter-spacing: -0.02em;
        text-transform: uppercase;

        .pag-num {
            font-weight: 500;
            font-size: 1rem;
            line-height: 162%;
            color: var(--neutral-50OP);
        }

        &.current {
            background-color: var(--primary-pure-100);

            a {
                color: var(--white);

            }
        }
    }

    .on-edges {

        font-weight: 500;
        font-size: 1rem;
        line-height: 162%;


        color: var(--primary-pure-100);



        a {
            display: flex;
            align-items: center;
            color: var(--primary-pure-100);
            border: none;
            gap: 8.33px;
        }

        svg {
            width: 20px;
            height: 20px;

        }
    }
}


  
html[lang="pt-br"],
html[lang="pt"]{
    [data-lang = 'en']{
        display: none;
    }
}

html[lang="en"]{
    [data-lang = "pt-br"],[data-lang="pt"]{
        display: none;
    }
}

// html[lang="pt-br"],
// html[lang="pt"]{
//     select option[value="langEN"] {
//         display: none !important;
//     }
// }

// html[lang="en"]{
//     [data-lang = "pt-br"],[data-lang="pt"]{
//         select option[value="langPT"] {
//             display: none !important;
//         }
//     }
// }

// html[lang="en"] body *{
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

body #goog-gt-tt{  
    display: none !important;
}

body font {
    background-color: transparent !important;
    box-shadow: none !important;
}